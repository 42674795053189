<template>
  <div id="invoice-page">
    <div class="contained-example-container">
      <div
        id="div-with-loading"
        class="con-vs-loading __container mt-6"
        v-if="!checkData"
      >
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <div v-if="checkData">
        <div class="items-center justify-between">
          <div class="flex items-center">
            <vs-button
              color="primary"
              class="m-2"
              type="filled"
              target
              :href="{ url: invoiceUrl }"
              >Imprimir</vs-button
            >
            <!-- <vs-button
              class="mb-base mr-3"
              icon-pack="fas"
              color="primary"
              icon="fa-print"
              type="border"
              @click="printInvoice"
            >Impresión</vs-button> -->
            <vs-button
              v-if="verificarFechaApartamento == true"
              class="mb-base mr-3 mt-8"
              icon-pack="feather"
              color="success"
              icon="icon-flag"
              @click="popupActive = true"
              >Crear reservar</vs-button
            >
            <vs-button
              class="mb-base mr-3 mt-8"
              icon-pack="feather"
              color="danger"
              icon="icon-lock"
              v-if="getQuoteByID.esign === 'false'"
              @click.native="openQuoteForSign('esign', 'true', 'Habilitada')"
              >Habilitar firma</vs-button
            >
            <vs-button
              class="mb-base mr-3"
              icon-pack="feather"
              color="success"
              icon="icon-unlock"
              v-if="getQuoteByID.esign === 'true'"
              @click.native="openQuoteForSign('esign', 'false', 'Inhabilitada')"
              >Firma habilitada</vs-button
            >
          </div>
          <!-- <div class="flex flex-wrap">
            <vx-input-group class="mb-base">
              <template slot="prepend">
                <div class="prepend-text bg-primary">
                  <span>Usuario:</span>
                </div>
              </template>
              <vs-input v-model="flattloUser" placeholder="E-mail" />
            </vx-input-group>
            <vs-button
              class="mb-base ml-3"
              icon-pack="feather"
              color="primary"
              icon="icon-send"
              disabled="true"
            ></vs-button>
          </div>-->

          <h5 class="mt-4 p-2">Resumen de cotización:</h5>
          <vs-divider></vs-divider>

          <vs-row vs-w="12">
            <vs-col
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="12"
              vs-xs="12"
              class="p-1"
            >
              <vx-card>
                <vs-list>
                  <vs-list-header
                    icon-pack="feather"
                    icon="icon-file-text"
                    title="Datos de cotización:"
                  ></vs-list-header>
                  <vs-list-item
                    icon-pack="feather"
                    icon="icon-hash"
                    title="Numero de cotización"
                    :subtitle="`${getQuoteByID.barCode}`"
                  ></vs-list-item>
                  <vs-list-item
                    icon-pack="feather"
                    icon="icon-calendar"
                    title="Fecha de solicitud"
                    :subtitle="
                      `${fechaFormateada(getQuoteByID.quote_date_created)}`
                    "
                  ></vs-list-item>
                  <vs-list-item
                    icon-pack="feather"
                    icon="icon-alert-triangle"
                    title="Fecha de vencimiento"
                    :subtitle="
                      `${fechaFormateada(getQuoteByID.quote_date_limit)}`
                    "
                  ></vs-list-item>
                </vs-list>
              </vx-card>

              <vx-card class="mt-3">
                <vs-list>
                  <vs-list-header
                    icon-pack="feather"
                    icon="icon-users"
                    title="Datos de cliente:"
                  ></vs-list-header>
                  <vs-list-item
                    icon-pack="feather"
                    icon="icon-user"
                    title="Nombre"
                    :subtitle="
                      `${getQuoteByID.client[0].first_name} ${getQuoteByID.client[0].last_name}`
                    "
                  ></vs-list-item>
                  <vs-list-item
                    icon-pack="feather"
                    icon="icon-phone-call"
                    title="Teléfono"
                    :subtitle="`${getQuoteByID.client[0].phone}`"
                  ></vs-list-item>
                  <vs-list-item
                    icon-pack="feather"
                    icon="icon-mail"
                    title="Correo"
                    :subtitle="`${getQuoteByID.client[0].email}`"
                  ></vs-list-item>
                </vs-list>
              </vx-card>

              <vx-card class="mt-3">
                <vs-list>
                  <vs-list-header
                    icon-pack="feather"
                    icon="icon-list"
                    title="Detalles financieros:"
                  ></vs-list-header>
                  <vs-list-item
                    icon="filter_1"
                    :title="
                      `${$tc('message.Reservation', 1, user.countryCode)}:`
                    "
                    :subtitle="
                      `Q. ${getQuoteByID.reserve_mount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
                    "
                  ></vs-list-item>
                  <vs-list-item
                    icon="description"
                    title="Firma promesa:"
                    :subtitle="
                      `Q. ${getQuoteByID.promise_sign_mount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
                    "
                  ></vs-list-item>
                  <vs-list-item
                    icon="filter_2"
                    title="Monto de enganche:"
                    :subtitle="
                      `Q. ${getQuoteByID.fraction_total_mount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} - ${
                        getQuoteByID.fraction_month_selected
                      } Meses `
                    "
                  ></vs-list-item>
                  <vs-list-item
                    v-if="jsonQuotes.length == 0"
                    icon="check"
                    title="Cuota nivelada de enganche:"
                    :subtitle="
                      `Q. ${getQuoteByID.fraction_fee_mount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
                    "
                  ></vs-list-item>
                  <br v-if="!(jsonQuotes.length == 0)" />
                  <vs-list v-if="!(jsonQuotes.length == 0)">
                    <vs-list-header
                      title="Cuotas personalizadas:"
                      color="grey"
                    ></vs-list-header>
                    <vs-list-item
                      icon-pack="feather"
                      v-for="v in jsonQuotes"
                      :key="v.index"
                      icon="icon-calendar"
                      :title="v.Mes"
                      :subtitle="`Q. ${v.Monto}`"
                    ></vs-list-item>
                    <br v-if="!(jsonQuotes.length == 0)" />
                  </vs-list>
                  <vs-list-item
                    icon="filter_3"
                    title="Monto de financiamiento:"
                    :subtitle="
                      `Q. ${getQuoteByID.financing_total_mount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
                    "
                  ></vs-list-item>
                  <vs-list-item
                    icon="account_balance"
                    title="Entidad seleccionada:"
                    :subtitle="
                      ` ${getQuoteByID.financing_name} - Tasa ${getQuoteByID.financing_interest_rate}% - ${getQuoteByID.financing_years_selected} Años`
                    "
                  ></vs-list-item>
                  <vs-list-item
                    icon="check"
                    title="Cuota nivelada:"
                    :subtitle="
                      `Q. ${getQuoteByID.financing_fee_mount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    "
                  ></vs-list-item>
                </vs-list>
              </vx-card>
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="flex-start"
              vs-lg="6"
              vs-sm="12"
              vs-xs="12"
              class="p-1"
            >
              <vx-card>
                <vs-list>
                  <vs-list-header
                    icon-pack="feather"
                    icon="icon-box"
                    :title="`Unidad: ${getQuoteByID.apartaments[0].number}`"
                  ></vs-list-header>
                  <vs-list-item
                    icon="local_hotel"
                    title="Habitaciones"
                    :subtitle="`${getQuoteByID.apartaments[0].bedrooms}`"
                  ></vs-list-item>
                  <vs-list-item
                    icon="bathtub"
                    title="Baños"
                    :subtitle="`${getQuoteByID.apartaments[0].bathrooms}`"
                  ></vs-list-item>
                  <vs-list-item
                    v-if="getProyect.parkings_assign == 'PRE'"
                    icon="drive_eta"
                    title="Parqueaderos"
                    :subtitle="
                      `${getQuoteByID.apartaments[0].parkings_assigned.length}`
                    "
                  ></vs-list-item>
                  <vs-list-item
                    v-if="getProyect.parkings_assign == 'FREE'"
                    icon="drive_eta"
                    title="Parqueaderos"
                    :subtitle="`${getQuoteByID.parkings.length}`"
                  ></vs-list-item>
                  <vs-list-item
                    icon-pack="feather"
                    icon="icon-check"
                    v-show="!(getQuoteByID.warehouses.length == 0)"
                    :title="$tc('message.warehouse', 2, user.countryCode)"
                    :subtitle="`${getQuoteByID.warehouses.length}`"
                  ></vs-list-item>
                  <vs-list-item
                    icon="texture"
                    title="m2"
                    :subtitle="
                      `${getQuoteByID.apartaments[0].living_square_mts}m2`
                    "
                  ></vs-list-item>
                  <vs-list-item
                    icon="local_offer"
                    title="Valor de propiedad"
                    :subtitle="
                      `Q. ${getQuoteByID.property_value
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    "
                  ></vs-list-item>
                  <vs-list-item
                    icon="local_offer"
                    title="Valor de parqueaderos"
                    v-if="getProyect.parkings_assign == 'PRE'"
                    :subtitle="
                      totalParqueos(
                        getQuoteByID.apartaments[0].parkings_assigned
                      )
                    "
                  ></vs-list-item>
                  <vs-list-item
                    icon="local_offer"
                    title="Valor de parqueaderos"
                    v-if="getProyect.parkings_assign == 'FREE'"
                    :subtitle="
                      `Q. ${getQuoteByID.parkings_mount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    "
                  ></vs-list-item>
                  <vs-list-item
                    icon="local_offer"
                    :title="
                      `Valor ${$tc('message.warehouse', 1, user.countryCode)}`
                    "
                    v-show="!(getQuoteByID.warehouses.length == 0)"
                    :subtitle="
                      `Q. ${getQuoteByID.warehouses_mount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    "
                  ></vs-list-item>
                  <vs-list-item
                    icon="account_balance"
                    title="Impuestos"
                    :subtitle="
                      `Q. ${getQuoteByID.taxes_mount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    "
                  ></vs-list-item>
                  <vs-list-item
                    icon="receipt"
                    title="Total"
                    :subtitle="
                      `Q. ${getQuoteByID.total_mount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    "
                  ></vs-list-item>
                </vs-list>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                  <img
                    id="planeOnQuote"
                    :src="getQuoteByID.apartaments[0].plane_img"
                  />
                </vs-col>
              </vx-card>
            </vs-col>
          </vs-row>

          <vs-popup
            title="Estas a punto de realizar una reserva"
            :active.sync="popupActive"
          >
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <p class="alerta-reserva">
                  ¿Deseas realizar la
                  {{ $tc("message.reservation", 1, user.countryCode) }} del
                  <b
                    >{{ $tc("message.Apartment", 1, user.countryCode) }}
                    {{ getQuoteByID.apartaments[0].number }}?</b
                  >
                </p>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <vs-button
                  ref="loadableButton"
                  icon-pack="fas"
                  color="success"
                  icon="fa-flag"
                  @click="loadigReservar()"
                  >Reservar</vs-button
                >
              </vs-col>
            </vs-row>
          </vs-popup>
        </div>
        <div v-if="checkData">
          <div class="flex flex-wrap items-center justify-between">
            <vs-popup
              :title="
                `Estas a punto de realizar una ${$tc(
                  'message.Reservation',
                  1,
                  user.countryCode
                )}`
              "
              :active.sync="popupActive"
            >
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="12"
                >
                  <p class="alerta-reserva">
                    ¿Deseas realizar la
                    {{ $tc("message.reservation", 1, user.countryCode) }} del
                    <b
                      >{{ $tc("message.Apartment", 1, user.countryCode) }}
                      {{ getQuoteByID.apartaments[0].number }}?</b
                    >
                  </p>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="12"
                >
                  <vs-button
                    ref="loadableButton"
                    icon-pack="fas"
                    color="success"
                    icon="fa-flag"
                    @click="loadigReservar()"
                    >Reservar</vs-button
                  >
                </vs-col>
              </vs-row>
            </vs-popup>
          </div>
          <vs-alert
            color="danger"
            v-if="verificarDisponibilidad == true"
            class="mt-5"
            >El {{ $tc("message.apartment", 1, user.countryCode) }} ya esta
            reservado.</vs-alert
          >
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import gql from "graphql-tag";
//import router from "../../router";
export default {
  data() {
    return {
      disponible: false,
      getQuoteByID: [],
      getDeveloper: [],
      getProyect: [],
      popupActive: false,
      lineWidth: 2,
      flattloUser: "",
      lineColor: "#000000",
      resultImg: "",
      isCrop: true
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    invoiceUrl() {
      const base = new URL(process.env.VUE_APP_GRAPHQL_HTTP);
      const url =
        base.protocol + "//" + base.host + "/pdf/" + localStorage.quoteID;
      return url;
    },
    jsonQuotes() {
      let Quotes = this.getQuoteByID.depositQuotes;
      return JSON.parse(Quotes);
    },
    verificarFechaApartamento() {
      let fecha1;
      let fecha2;
      let ApartamentoStatus;
      if (!this.getQuoteByID) {
        return false;
      } else {
        fecha1 = new Date(this.getQuoteByID.quote_date_limit);
        fecha2 = new Date();

        ApartamentoStatus =
          this.getQuoteByID.apartaments[0].actual_state == undefined
            ? false
            : this.getQuoteByID.apartaments[0].actual_state;
        if (fecha1 > fecha2 && ApartamentoStatus == "Disponible") {
          return true;
        } else {
          return false;
        }
      }
    },
    verificarDisponibilidad() {
      let ApartamentoStatus;
      if (!this.getQuoteByID) {
        return false;
      } else {
        ApartamentoStatus =
          this.getQuoteByID.apartaments[0].actual_state == undefined
            ? true
            : this.getQuoteByID.apartaments[0].actual_state;

        if (ApartamentoStatus == "Disponible") {
          return false;
        } else {
          return true;
        }
      }
    },
    checkData() {
      if (this.getQuoteByID == undefined) {
        return false;
      } else {
        return true;
      }
    },
    getDate() {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      let yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    }
  },
  methods: {
    totalParqueos(parqueos) {
      let Total = 0;
      parqueos.forEach(element => {
        Total += element.price;
      });
      return Total.toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getQuoteToThisByID() {
      this.$apollo
        .query({
          query: gql`
            query($quoteID: ID!) {
              getQuoteByID(quoteID: $quoteID) {
                _id
                developerCompanyName
                barCode
                developerCompanyName
                quote_date_created
                quote_date_limit
                discount_mount
                reserve_mount
                promise_sign_mount
                fraction_total_mount
                fraction_month_selected
                fraction_fee_mount
                property_value
                parkings_mount
                warehouses_mount
                taxes_mount
                total_mount
                financing_name
                financing_total_mount
                financing_interest_rate
                financing_years_selected
                financing_fee_mount
                proyect_name
                logo_quote_proyect
                quote_bank_calification_requirements
                general_apartament_description
                quote_terms
                esign
                depositQuotes
                client {
                  first_name
                  last_name
                  email
                  phone
                }
                apartaments {
                  proyect_id
                  price
                  number
                  living_square_mts
                  bedrooms
                  bathrooms
                  plane_img
                  actual_state
                  parkings_assigned {
                    _id
                    number
                    price
                  }
                }
                parkings {
                  price
                }
                warehouses {
                  price
                }
              }
            }
          `,
          variables: {
            quoteID: localStorage.quoteID
          }
        })
        .then(data => {
          this.getQuoteByID = data.data.getQuoteByID;
          this.getToProyect(data.data.getQuoteByID.apartaments[0].proyect_id);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getToDeveloper() {
      this.$apollo
        .query({
          query: gql`
            query getDeveloperData($id: ID!) {
              getDeveloper(id: $id) {
                _id
                name
                phone
                email
                website
                address
              }
            }
          `,
          variables: {
            id: localStorage.company_id
          }
        })
        .then(data => {
          this.getDeveloper = data.data.getDeveloper;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getToUser() {
      this.$apollo
        .query({
          query: gql`
            query($userID: ID!) {
              getUser(id: $userID) {
                _id
                email
                phone
                first_name
                last_name
                pic
              }
            }
          `,
          variables: {
            userID: localStorage.user_id
          }
        })
        .then(data => {
          this.getUser = data.data.getUser;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getToProyect(id) {
      this.$apollo
        .query({
          query: gql`
            query getProyectData($proyectID: String!) {
              getProyect(proyectID: $proyectID) {
                _id
                parkings_assign
              }
            }
          `,
          variables: {
            proyectID: id
          }
        })
        .then(data => {
          this.getProyect = data.data.getProyect;
        })
        .catch(err => {
          console.log(err);
        });
    },
    openLoadingAdvanceSearch() {
      this.$vs.loading();
    },
    openQuoteForSign(objectField, value, status) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($quoteID: ID!, $objectField: String!, $value: String!) {
              editStringQuote(
                ID: $quoteID
                objectField: $objectField
                value: $value
              ) {
                value
              }
            }
          `,
          variables: {
            quoteID: this.getQuoteByID._id,
            objectField,
            value
          }
        })
        .then(
          this.$vs.notify({
            title: `Firma ${status}`,
            text: `"Cambio correcto realizado"`,
            color: "success",
            iconPack: "feather",
            icon: "icon-check"
          })
        )
        .catch(err => {
          console.log(err);
        });
    },
    handleReset() {
      this.$refs.esign.reset();
      this.bgColor = "#ededed";
    },
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then(res => {
          this.resultImg = res;
        })
        .catch(err => {
          alert(err);
        });
    },

    printInvoice() {
      const base = new URL(process.env.VUE_APP_GRAPHQL_HTTP);
      const url =
        base.protocol + "//" + base.host + "/pdf/" + localStorage.quoteID;
      window.open(url, "_blank");
      //document.title = `${this.getQuoteByID.barCode +
      //  "-" +
      //  this.getQuoteByID.client[0].first_name +
      //  "-" +
      //  this.getQuoteByID.client[0].last_name}`;
      //window.print();
      //window.onafterprint = function(event) {
      //  console.log(event);
      //};
    },

    fechaFormateada(fecha) {
      let fechaFormateada = new Date(fecha);
      let dd = fechaFormateada.getDate();
      let mm = fechaFormateada.getMonth() + 1;
      let y = fechaFormateada.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      let fechaFull = dd + "/" + mm + "/" + y;

      return fechaFull;
    },
    loadigReservar() {
      this.popupActive = false;
      this.$vs.loading({
        type: "sound"
      });
      setTimeout(() => {
        this.generarReserva();
      }, 4000);
    },
    generarReserva() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation(
              $userID: String!
              $developerID: String!
              $quotesID: String!
            ) {
              newReserve(
                userID: $userID
                developerID: $developerID
                quotesID: $quotesID
              ) {
                _id
                date_created
                quote {
                  proyect_name
                  apartaments {
                    number
                  }
                }
              }
            }
          `,
          variables: {
            userID: localStorage.user_id,
            developerID: localStorage.company_id,
            quotesID: this.getQuoteByID._id
          }
        })
        .then(data => {
          this.addReservetoDeveloper(
            data.data.newReserve._id,
            data.data.newReserve.quote[0].apartaments[0].number
          );
        })
        .catch(error => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `No ha sido posible hacer la ${this.$tc(
              "message.reservation",
              1,
              this.user.countryCode
            )}`,
            text: `${error.message}`,
            iconPack: "feather",
            icon: "icon-x-circle",
            color: "danger",
            time: 8000
          });
        });
    },
    addReservetoDeveloper(id, nApartamento) {
      const the = this;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($reserveID: ID!, $developerID: ID!) {
              addReserveToDeveloper(
                reserveID: $reserveID
                developerID: $developerID
              ) {
                _id
                name
              }
            }
          `,
          variables: {
            reserveID: id,
            developerID: localStorage.company_id
          }
        })
        .then(function() {
          the.$vs.loading.close();
          //router.push({ path: "/reservas" });
          the.createNotification();
          the.$vs.notify({
            title: `👏👏 Felicidades!!! 🎉🎉`,
            text: `Se realizo con exito la ${this.$tc(
              "message.reservation",
              1,
              this.user.countryCode
            )} del ${this.$tc(
              "message.Apartment",
              1,
              this.user.countryCode
            )} ${nApartamento}`,
            iconPack: "feather",
            icon: "icon-x-circle",
            color: "success",
            time: 8000
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    createNotification() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation(
              $name: String!
              $description: String!
              $icon: String!
              $type: String
              $date_created: String!
              $company_id: String!
            ) {
              newNotification(
                name: $name
                description: $description
                icon: $icon
                type: $type
                date_created: $date_created
                company_id: $company_id
              ) {
                _id
              }
            }
          `,
          // Parameters
          variables: {
            name: `${this.$tc(
              "message.reservation",
              1,
              this.user.countryCode
            )} generada 🎉`,
            description:
              "Unidad #" +
              this.getQuoteByID.apartaments[0].number +
              " en proyecto " +
              this.getQuoteByID.proyect_name +
              "",
            icon: "FlagIcon",
            type: "primary",
            date_created: new Date(),
            company_id: localStorage.company_id
          }
        })
        .then(data => {
          let NotificationId = data.data.newNotification._id;
          this.addNotificationToDeveloper(
            localStorage.company_id,
            NotificationId
          );
        })
        .catch(error => {
          console.error(error);
        });
    },
    addNotificationToDeveloper(companyID, NotificationId) {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($developerID: ID!, $notifyID: ID!) {
              addNotificationToDeveloper(
                developerID: $developerID
                notifyID: $notifyID
              ) {
                _id
              }
            }
          `,
          // Parameters
          variables: {
            developerID: companyID,
            notifyID: NotificationId
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  components: {},
  mounted() {
    this.getQuoteToThisByID();
    this.getToDeveloper();
    this.getToUser();
    this.openLoadingAdvanceSearch();
    setTimeout(() => {
      this.$vs.loading.close();
    }, 2000);
    this.$emit("setAppClasses", "invoice-page");
  }
};
</script>

<style lang="scss">
.vs-textarea {
  height: 250px;
}
.detailArea {
  width: 75% !important;
}
.eSignArea {
  background-color: #ededed !important;
}
.alerta-reserva {
  margin-bottom: 20px;
}
#planeOnQuote {
  display: block;
  width: 84%;
  padding: 22px;
  margin-bottom: 32px;
}

#LogoProyectQuote {
  display: block;
  width: 50%;
  margin-bottom: 25px;
}
#LogoProyectQuote2 {
  display: block;
  width: 50%;
  margin-bottom: 25px;
}

#LogoProyectQuote3 {
  display: block;
  width: 50%;
  margin-bottom: -10px;
}
@page {
  size: auto; /* auto is the initial value */
  margin: 0; /* this affects the margin in the printer settings */
}
@media print {
  .invoice-page {
    * {
      visibility: hidden;
    }

    #content-area {
      margin: 0 !important;
    }

    #invoice-container,
    #invoice-container * {
      visibility: visible;
      background-repeat: no-repeat;
    }
    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
    #planeOnQuote {
      display: block;
      position: absolute;
      width: 50%;
      margin-top: 0vh;
      z-index: 1;
    }

    #QuoteNumber {
      margin-top: -6vh;
      display: block;
    }

    #QuoteNumber2 {
      margin-top: -10vh;
      display: block;
    }

    #LogoProyectQuote {
      display: block;
      position: relative;
      width: 35%;
      margin-top: -45px;
    }

    #LogoProyectQuote2 {
      display: block;
      position: relative;
      width: 35%;
      margin-top: 30px;
    }
    #LogoProyectQuote3 {
      display: block;
      position: relative;
      width: 35%;
      margin-bottom: -20px;
      margin-top: 35px;
    }
    #CompanyDetail {
      margin-top: 0vh;
    }
    #ClientDetail {
      position: absolute;
      display: block;
      margin-top: 9vh;
      background-repeat: no-repeat;
    }
    #QuoteTableSteps {
      position: relative;
      display: block;
      z-index: 999999;
      margin-top: -6vh;
    }
    .chipColor {
      background-color: #000;
    }
  }
}
</style>
